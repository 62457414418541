import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { TOKEN } from "../../services/Constants/systems";
import { httpClient } from "../../services/Connection/httpclient";
import config from "../../config";

// Interface สำหรับ State
interface FloodgateTransationMonitorState {
    data: Array<Record<string, Record<string, string>>> | null;
    isLoading: boolean;
    isError: boolean;
    errorMessage: string | null;
}

const initialState: FloodgateTransationMonitorState = {
    data: null,
    isLoading: false,
    isError: false,
    errorMessage: null,
};

export const fetchTransationMonitor = createAsyncThunk<
    Array<Record<string, Record<string, string>>>,
    {
        floodgate_id: string,
        number_of_rows: string,
        page: string,
        startdate: string,
        enddate: string,
    },
    { rejectValue: string }
>(
    "transationMonitor/fetchTransationMonitor",
    async ({ floodgate_id, number_of_rows, page, startdate, enddate }, { rejectWithValue }) => {
        try {
            const token = localStorage.getItem(TOKEN);
            if (!token) {
                return rejectWithValue("Token is missing");
            }

            const req = (number_of_rows && page && startdate && enddate) ? {
                floodgateId: floodgate_id,
                numberofrows: number_of_rows,
                page: page,
                transaction_startdate: startdate,
                transaction_enddate: enddate,
            } : { floodgateId: floodgate_id };

            const response = await httpClient.post(
                `${config.floodgate.floodgate_url}/floodgate/transation/monitor`, req,
                {
                    headers: {
                        'authorization': token,
                        'Content-Type': 'application/json',
                    },
                }
            );

            if (response.data.status) {
                return response.data.data;
            } else {
                return rejectWithValue(response.data.message || "ไม่สามารถดึงข้อมูลได้");
            }

        } catch (error: any) {
            return rejectWithValue(error.message || "เกิดข้อผิดพลาด");
        }
    }
);

// สร้าง Slice
const transationMonitorSlice = createSlice({
    name: "transationMonitor",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchTransationMonitor.pending, (state) => {
                state.isLoading = true;
                state.isError = false;
                state.errorMessage = null;
            })
            .addCase(
                fetchTransationMonitor.fulfilled,
                (state, action: PayloadAction<Array<Record<string, Record<string, string>>>>) => {
                    state.isLoading = false;
                    state.data = action.payload;
                }
            )
            .addCase(
                fetchTransationMonitor.rejected,
                (state, action: PayloadAction<string | undefined>) => {
                    state.isLoading = false;
                    state.isError = true;
                    state.errorMessage = action.payload || "เกิดข้อผิดพลาด";
                }
            );
    },
});

// Selector สำหรับดึงข้อมูล transation monitor จาก store
export const transationMonitorSelector = (store: RootState) => store.transationMonitor;

// Export reducer
export default transationMonitorSlice.reducer;