import React, { useEffect, useState } from 'react';
import { Box, Modal, Fade, Backdrop, Typography, Button, TablePagination, TextField } from '@mui/material';
import { DataGrid, GridColDef, GridToolbarContainer, GridToolbarContainerProps } from '@mui/x-data-grid';
import { useAppDispatch, useAppSelector } from '../../../../stores/store';
import { fetchTransationReport, transationReportSelector } from '../../../../stores/slices/FloodgateTransationReportSlice';
import { DateTimePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { FloodgateDetail } from '../../../../models/monitor/MonitorDetailResponse.model';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

interface ModalProps {
    open: boolean;
    floodgateID: string;
    onClose: () => void;
    dataMonitorDetail: FloodgateDetail | null;
}

const MonitorTransactionModal: React.FC<ModalProps> = ({ open, floodgateID, onClose, dataMonitorDetail }) => {
    const dispatch = useAppDispatch();
    const { data: monitorTxnData, isLoading, isError, errorMessage } = useAppSelector(transationReportSelector);
    const [columns, setColumns] = useState<GridColDef[]>([]);
    const [rows, setRows] = useState<any[]>([]);
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 10,
    });

    // State สำหรับช่วงเวลาที่เลือก
    const [startDate, setStartDate] = useState<Date | null>(new Date());
    const [endDate, setEndDate] = useState<Date | null>(new Date());

    useEffect(() => {
        if (startDate && endDate) {
            const formatDate = (date: Date) => {
                const year = date.getFullYear();
                const month = String(date.getMonth() + 1).padStart(2, '0');
                const day = String(date.getDate()).padStart(2, '0');
                const hours = String(date.getHours()).padStart(2, '0');
                const minutes = String(date.getMinutes()).padStart(2, '0');
                const seconds = String(date.getSeconds()).padStart(2, '0');
                return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
            };

            dispatch(fetchTransationReport({
                floodgate_id: floodgateID || "",
                number_of_rows: `${paginationModel.pageSize}`,
                page: `${paginationModel.page + 1}`,
                startdate: formatDate(startDate),
                enddate: formatDate(endDate),
            }));
        }
    }, [floodgateID, paginationModel, dispatch, startDate, endDate]);

    useEffect(() => {
        if (monitorTxnData && monitorTxnData.length > 0) {
            const dynamicColumns: GridColDef[] = [
                {
                    field: 'floodgate_transaction_createdate',
                    headerName: 'Transaction Date',
                    width: 200,
                }
            ];
            const rowData: any[] = [];

            monitorTxnData.forEach((item, index) => {  // เพิ่ม index เพื่อสร้าง id ที่ไม่ซ้ำ
                const floodgateKey = Object.keys(item)[0]; // Assume key is "1", "2", etc.
                const floodgateData = item[floodgateKey]; // Access the data under the floodgate ID key

                // Generate dynamic columns based on keys in floodgateData (except floodgate_transaction_createdate)
                Object.keys(floodgateData).forEach((key) => {
                    if (key !== 'floodgate_transaction_createdate') {
                        if (!dynamicColumns.some((col) => col.field === key)) {
                            dynamicColumns.push({
                                field: key,
                                headerName: key,
                                width: 200,
                                renderCell: (params) => {
                                    if (typeof params.value === 'string' && params.value.includes(',')) {
                                        const values = params.value.split(',');
                                        return values.map((val, index) => <div key={index}>{val}</div>);
                                    }
                                    return params.value;
                                }
                            });
                        }
                    }
                });

                const row = { id: `${floodgateKey}-${index}`, ...floodgateData };  // เพิ่ม index เพื่อให้ id ไม่ซ้ำ
                rowData.push(row);
            });

            setColumns(dynamicColumns);
            setRows(rowData);
        }
    }, [monitorTxnData, dataMonitorDetail]);

    // ฟังก์ชันดาวน์โหลด Excel
    const handleDownloadExcel = () => {
        const ws = XLSX.utils.json_to_sheet(rows);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Data');
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
        saveAs(data, 'transaction_report.xlsx');
    };

    const CustomToolbar = (props: GridToolbarContainerProps) => (
        <GridToolbarContainer {...props}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                    label="Start Date"
                    value={startDate}
                    onChange={(newValue) => setStartDate(newValue)}
                    format='yyyy-MM-dd HH:mm:ss'
                    views={['year', 'month', 'day', 'hours', 'minutes', 'seconds']}
                    ampm={false}
                    sx={{ m: 2 }}
                />

                <DateTimePicker
                    label="End Date"
                    value={endDate}
                    onChange={(newValue) => setEndDate(newValue)}
                    format='yyyy-MM-dd HH:mm:ss'
                    views={['year', 'month', 'day', 'hours', 'minutes', 'seconds']}
                    ampm={false}
                    sx={{ m: 2 }}
                />
            </LocalizationProvider>

            <Button
                variant="contained"
                color="primary"
                onClick={handleDownloadExcel}
                sx={{ ml: 2 }}
            >
                Download Excel
            </Button>
        </GridToolbarContainer>
    );

    const DataGridPagination = () => (
        <TablePagination
            component="div"
            count={100}  // สมมติว่ามีข้อมูลทั้งหมด 100 แถว
            page={paginationModel.page}
            onPageChange={(_, newPage) => setPaginationModel((prev) => ({ ...prev, page: newPage }))}
            rowsPerPage={paginationModel.pageSize}
            onRowsPerPageChange={(event) =>
                setPaginationModel((prev) => ({
                    ...prev,
                    pageSize: parseInt(event.target.value, 10),
                }))
            }
        />
    );

    if (isLoading) return <Typography>Loading...</Typography>;
    if (isError) return <Typography>Error: {errorMessage}</Typography>;

    return (
        <Modal open={open} onClose={onClose} closeAfterTransition BackdropComponent={Backdrop} BackdropProps={{ timeout: 500 }}>
            <Fade in={open}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '80%',
                        minHeight: '80vh',
                        maxHeight: '80vh',
                        bgcolor: 'background.paper',
                        borderRadius: 2,
                        boxShadow: 24,
                        p: 4,
                        overflowY: 'auto',
                    }}
                >
                    <Typography variant="h6" gutterBottom>ข้อมูล transaction</Typography>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        autoHeight
                        getRowId={(row) => row.id}
                        paginationModel={paginationModel}
                        onPaginationModelChange={(newModel) => setPaginationModel(newModel)}
                        pagination
                        paginationMode="server"
                        rowCount={100}  // จำนวนแถวทั้งหมด (สมมติว่ามี 100 แถว)
                        slots={{ toolbar: CustomToolbar, pagination: DataGridPagination }}
                        sx={{
                            minHeight: '60vh',
                            maxHeight: '60vh',
                        }}
                    />
                </Box>
            </Fade>
        </Modal>
    );
};

export default MonitorTransactionModal;
