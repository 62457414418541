import React, { useState, useEffect } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Paper, Box, Typography, Button, Grid, Modal, Backdrop, Fade, CircularProgress } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/system';
import { useAppDispatch, useAppSelector } from '../../../stores/store';
import { floodGateInfoSelector, fetchFloodGateInfo } from '../../../stores/slices/FloodGateInfoSlice';
import { SettingNode, FloodgateInfo } from '../../../models/floodgateInfo/FloodGateInfoGuestRes.model';
import { floodgateAction } from '../../../services/Constants/enum/enum.model';
import { io } from 'socket.io-client';
import config from '../../../config';

interface FloodgateGroupWithSchema {
  floodgate_schema_type_id: string;
  floodgate_schema_type_name: string;
  floodgate_info: FloodgateInfo[];
}

const TransparentAccordion = styled(Accordion)(({ theme }) => ({
  backgroundColor: '#EFEFEF',
  borderRadius: '8px',
  boxShadow: 'none',
  marginBottom: theme.spacing(2),
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  borderRadius: '12px',
  backgroundColor: 'rgba(255, 255, 255, 1)',
  boxShadow: '0 3px 5px rgba(0, 0, 0, 0.2)',
  transition: 'transform 0.2s ease-in-out',
  '&:hover': {
    transform: 'scale(1.05)',
  },
}));

const CustomModal = styled(Modal)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

interface CustomCardProps {
  title: string;
  description: React.ReactNode;
  image: string;
  mapView: string;
  onViewDetails: () => void;
  handleMapOpen: (mapHtml: string) => void;
}

function CustomCard({ title, description, image, mapView, onViewDetails, handleMapOpen }: CustomCardProps) {
  const [imgSrc, setImgSrc] = useState(image);
  const handleImageError = () => {
    setImgSrc(`${process.env.PUBLIC_URL}/images/no-photo-available.png`);
  };
  return (
    <StyledPaper>
      <Box
        component="img"
        src={imgSrc}
        alt={title}
        onError={handleImageError}
        sx={{
          height: 140,
          width: '100%',
          objectFit: 'cover',
          borderRadius: '8px',
          marginBottom: 2,
        }}
      />
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      <Typography variant="h6" color="text.secondary" gutterBottom>
        {description}
      </Typography>
      <Button variant="contained" color="primary" size="small" onClick={onViewDetails}>
        ดูข้อมูลเพิ่มเติม
      </Button>
      {
        mapView && mapView !== null && mapView !== "" && mapView !== "NA" && (
          <Button variant="contained" color="secondary" size="small" onClick={() => handleMapOpen(mapView)} sx={{ marginLeft: '5px' }}>
            แผนที่
          </Button>
        )
      }
    </StyledPaper>
  );
}

export default function FloodGateList() {
  const dispatch = useAppDispatch();
  const { dataByGuest, isErrorByGuest, isLoadingByGuest, errorMessageByGuest } = useAppSelector(floodGateInfoSelector);

  const [open, setOpen] = useState(false);
  const [openMap, setOpenMap] = useState(false);
  const [currentSettings, setCurrentSettings] = useState<SettingNode[]>([]);
  const [currentFloodgateName, setCurrentFloodgateName] = useState<string>('');
  const [currentFloodgateID, setCurrentFloodgateID] = useState<string>('');
  const [socketData, setSocketData] = useState<{ [key: string]: { [key: string]: string } }>({});
  const [currentMapHtml, setCurrentMapHtml] = useState<string>('');

  useEffect(() => {
    dispatch(fetchFloodGateInfo(floodgateAction.getAllByGuest));

    const socket = io(config.ws.ws_floodgate_guest_url);

    socket.on('data', (data) => {
      const newData = data.reduce((acc: any, item: any) => {
        acc[item.floodgate_id] = item.floodgate_transaction_details;
        acc[item.floodgate_id].floodgate_transaction_createdate = item.floodgate_transaction_createdate;
        return acc;
      }, {});

      setSocketData(newData);
    });

    return () => {
      socket.disconnect();
    };
  }, [dispatch]);

  const handleMapOpen = (mapHtml: string) => {
    setCurrentMapHtml(mapHtml);
    setOpenMap(true);
  };

  const handleMapClose = () => {
    setOpenMap(false);
  };

  const handleOpen = (settings: SettingNode[], floodgateName: string, floodgateID: string) => {
    setCurrentSettings(settings);
    setCurrentFloodgateName(floodgateName);
    setCurrentFloodgateID(floodgateID);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (isLoadingByGuest) return <CircularProgress sx={{ display: 'block', margin: '20px auto' }} />;
  if (isErrorByGuest) return <Typography>เกิดข้อผิดพลาด: {errorMessageByGuest}</Typography>;

  const transformedData = Array.isArray(dataByGuest) ? dataByGuest : [];

  const handleArr = (setting: SettingNode, data: string) => {
    if (data != "0") {
      if (setting.setting_node_data_type == "1") { // 1 = Array
        const dataArray = data.split(',');
        const index = parseInt(setting.setting_node_sequence);
        if (index >= 0 && index < dataArray.length) {
          const inputValue = parseFloat(dataArray[index]);
          if (!isNaN(inputValue)) {
            return inputValue.toFixed(2);
          }
          return dataArray[index];
        } else {
          return "0";
        }
      } else {
        return data;
      }
    }
    return "0";
  }

  return (
    <div>
      {transformedData.map((group: FloodgateGroupWithSchema) => {
        if (!group.floodgate_info || group.floodgate_info.length === 0) {
          return null; // ข้ามการแสดงผล Accordion นี้
        }

        return (
          <TransparentAccordion key={`group-${group.floodgate_schema_type_id}`} defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6">{group.floodgate_schema_type_name}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={3}>
                {group.floodgate_info.map((info: FloodgateInfo) => {
                  const latestSettings = info.setting_nodes.slice(0, 2).map((setting: SettingNode, index: number) => (
                    <React.Fragment key={`${setting.setting_node_data_key}-${index}`}>
                      <Box>
                        {setting.setting_node_data_name} : {handleArr(setting, socketData[info.floodgate_id]?.[setting.setting_node_data_key] || '0') || '0'} {setting.setting_node_data_unit}
                      </Box>
                    </React.Fragment>
                  ));

                  return (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={info.floodgate_name}>
                      <CustomCard
                        title={info.floodgate_name}
                        description={latestSettings}
                        image={info.floodgate_attachment_file}
                        mapView={info.floodgate_map}
                        onViewDetails={() => handleOpen(info.setting_nodes, info.floodgate_name, info.floodgate_id)}
                        handleMapOpen={() => handleMapOpen(info.floodgate_map)}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </AccordionDetails>
          </TransparentAccordion>
        )
      })
      }

      <CustomModal open={open} onClose={handleClose} closeAfterTransition>
        <Fade in={open}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: { xs: '90%', sm: 'auto', md: 'auto' },
              maxWidth: '100%',
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
              borderRadius: 2,
              maxHeight: '500px',
              overflowY: 'auto',
            }}
          >
            <Typography variant="h5" textAlign="center" gutterBottom>
              ข้อมูลเพิ่มเติม ({currentFloodgateName})
            </Typography>
            {currentSettings.map((setting, index) => (
              <Paper key={index} sx={{ p: 1.5, mb: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between', borderRadius: '8px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}>
                <Box width={'100%'}>
                  <Typography variant="h6" textAlign="left">
                    {setting.setting_node_data_name} : {handleArr(setting, socketData[currentFloodgateID]?.[setting.setting_node_data_key] || '0') || '0'} {setting.setting_node_data_unit}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" width={'100%'} textAlign="right">
                    <Box component="span" ml="1rem"
                      sx={{
                        display: { xs: 'block', sm: 'inline' },
                        backgroundColor: 'rgba(95, 163, 224, 0.8)',
                        padding: '2px 4px',
                        borderRadius: '4px'
                      }}>
                      {socketData[currentFloodgateID]?.['floodgate_transaction_createdate']}
                    </Box>
                  </Typography>
                </Box>
              </Paper>
            ))}
          </Box>
        </Fade>
      </CustomModal>

      <CustomModal open={openMap} onClose={handleMapClose} closeAfterTransition>
        <Fade in={openMap}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 'auto',
              maxHeight: '80vh',
              bgcolor: 'background.paper',
              borderRadius: '12px',
              boxShadow: 24,
              p: 2,
            }}
            dangerouslySetInnerHTML={{ __html: currentMapHtml }}
          />
        </Fade>
      </CustomModal>
    </div >
  );
}