import React, { useEffect, useState } from 'react';
import { Box, Modal, Fade, Backdrop, Typography, Button, TablePagination } from '@mui/material';
import { DataGrid, GridColDef, GridToolbarContainer, GridToolbarContainerProps } from '@mui/x-data-grid';
import { useAppDispatch, useAppSelector } from '../../../../stores/store';
import { fetchMonitorLogsReport, monitorLogsReportSelector } from '../../../../stores/slices/MonutorLogsReportSlice';
import { DateTimePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

interface ModalProps {
    open: boolean;
    floodgateID: string;
    onClose: () => void;
}

const MonitorLogsModal: React.FC<ModalProps> = ({ open, floodgateID, onClose }) => {
    const dispatch = useAppDispatch();
    const { data: monitorTxnData, isLoading, isError, errorMessage } = useAppSelector(monitorLogsReportSelector);
    const [columns, setColumns] = useState<GridColDef[]>([]);
    const [rows, setRows] = useState<any[]>([]);
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 10,
    });

    // State สำหรับช่วงเวลาที่เลือก
    const [startDate, setStartDate] = useState<Date | null>(new Date());
    const [endDate, setEndDate] = useState<Date | null>(new Date());

    useEffect(() => {
        if (startDate && endDate) {
            const formatDate = (date: Date) => {
                const year = date.getFullYear();
                const month = String(date.getMonth() + 1).padStart(2, '0');
                const day = String(date.getDate()).padStart(2, '0');
                const hours = String(date.getHours()).padStart(2, '0');
                const minutes = String(date.getMinutes()).padStart(2, '0');
                const seconds = String(date.getSeconds()).padStart(2, '0');
                return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
            };

            dispatch(fetchMonitorLogsReport({
                floodgate_id: floodgateID || "",
                number_of_rows: `${paginationModel.pageSize}`,
                page: `${paginationModel.page + 1}`,
                startdate: formatDate(startDate),
                enddate: formatDate(endDate),
            }));
        }
    }, [floodgateID, paginationModel, dispatch, startDate, endDate]);

    useEffect(() => {
        if (monitorTxnData && monitorTxnData.length > 0) {
            const dynamicColumns: GridColDef[] = [
                //{ field: 'account_id', headerName: 'Account ID', width: 150 },
                { field: 'account_name', headerName: 'Account Name', width: 200 },
                { field: 'action', headerName: 'Action', width: 150 },
                { field: 'value', headerName: 'Value', width: 150 },
                { field: 'log_create', headerName: 'Log Date', width: 200 },
            ];
            const rowData: any[] = [];

            monitorTxnData.forEach((item: { [key: string]: any }, index: number) => {
                const floodgateKey = Object.keys(item)[0];
                const floodgateData = item[floodgateKey];
                const row = { id: `${floodgateKey}-${index}`, ...floodgateData };
                rowData.push(row);
            });

            setColumns(dynamicColumns);
            setRows(rowData);
        }
    }, [monitorTxnData]);

    // ฟังก์ชันดาวน์โหลด Excel
    const handleDownloadExcel = () => {
        const ws = XLSX.utils.json_to_sheet(rows);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Data');
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
        saveAs(data, 'monitor_logs_report.xlsx');
    };

    const CustomToolbar = (props: GridToolbarContainerProps) => (
        <GridToolbarContainer {...props}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                    label="Start Date"
                    value={startDate}
                    onChange={(newValue) => setStartDate(newValue)}
                    views={['year', 'month', 'day', 'hours', 'minutes', 'seconds']}
                    ampm={false}
                    sx={{ m: 2 }}
                />

                <DateTimePicker
                    label="End Date"
                    value={endDate}
                    onChange={(newValue) => setEndDate(newValue)}
                    views={['year', 'month', 'day', 'hours', 'minutes', 'seconds']}
                    ampm={false}
                    sx={{ m: 2 }}
                />
            </LocalizationProvider>

            <Button
                variant="contained"
                color="primary"
                onClick={handleDownloadExcel}
                sx={{ ml: 2 }}
            >
                Download Excel
            </Button>
        </GridToolbarContainer>
    );

    const DataGridPagination = () => (
        <TablePagination
            component="div"
            count={100}
            page={paginationModel.page}
            onPageChange={(_, newPage) => setPaginationModel((prev) => ({ ...prev, page: newPage }))}
            rowsPerPage={paginationModel.pageSize}
            onRowsPerPageChange={(event) =>
                setPaginationModel((prev) => ({
                    ...prev,
                    pageSize: parseInt(event.target.value, 10),
                }))
            }
        />
    );

    if (isLoading) return <Typography>Loading...</Typography>;
    if (isError) return <Typography>Error: {errorMessage}</Typography>;

    return (
        <Modal open={open} onClose={onClose} closeAfterTransition BackdropComponent={Backdrop} BackdropProps={{ timeout: 500 }}>
            <Fade in={open}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '80%',
                        minHeight: '80vh',
                        maxHeight: '80vh',
                        bgcolor: 'background.paper',
                        borderRadius: 2,
                        boxShadow: 24,
                        p: 4,
                        overflowY: 'auto',
                    }}
                >
                    <Typography variant="h6" gutterBottom>ข้อมูล Monitor Logs</Typography>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        autoHeight
                        getRowId={(row) => row.id}
                        paginationModel={paginationModel}
                        onPaginationModelChange={(newModel) => setPaginationModel(newModel)}
                        pagination
                        paginationMode="server"
                        rowCount={100}
                        slots={{ toolbar: CustomToolbar, pagination: DataGridPagination }}
                        sx={{
                            minHeight: '60vh',
                            maxHeight: '60vh',
                        }}
                    />
                </Box>
            </Fade>
        </Modal>
    );
};

export default MonitorLogsModal;